<template>
  <div class="top-button-list" ref="topButtonList" @mousewheel="handleMouseWheel">
    <span class="shortcut-prompt">
      <a-popover placement="bottomLeft">
        <template slot="content">
          <div>
            <div>留言：N</div>
            <div>批注：W</div>
            <div>撤回：Ctrl+Z</div>
            <div>重做：Ctrl+Y</div>
            <div>文本框（显/隐）：Ctrl+H</div>
            <div>滚动画布：方向键↑↓</div>
          </div>
        </template>
        <span>
          快捷键提示
        </span>
      </a-popover>
    </span>
    <div>
      <div class="top-item" :style="isAutoSave ? '' : 'cursor: no-drop;'">
        <div
          @click="changeAutoSave"
          :style="isAutoSave ? '' : 'cursor: no-drop;background-color: rgba(109, 109, 109, 0);'"
        >
          <a-tooltip>
            <template slot="title">
              保存 CTRL+S
            </template>
            <save theme="outline" size="20" :fill="isAutoSave ? color : saveColor" :strokeWidth="4" />
          </a-tooltip>
        </div>
      </div>
      <div
        v-if="checkPermission('fatchStraw:upload:view')"
        class="top-item"
        :style="isAutoSave ? '' : 'cursor: no-drop;'"
      >
        <div @mouseover="downLoadColor = color" @mouseout="downLoadColor = '#4a4a4a'">
          <a-tooltip @mouseover.stop @mouseout.stop transitionName="">
            <template slot="title">
              <span>导出</span>
            </template>
            <a-dropdown :trigger="['click']" transitionName="">
              <div style="position:relative;top:2px;width:40px">
                <down-picture theme="outline" size="20" :fill="downLoadColor" :strokeWidth="3" /><a-icon
                  type="down"
                  style="font-size:12px;position: relative;top: -3px;right: -4px;"
                />
                <div class="top-item-hover" style="top:-4px;width:46px;"></div>
              </div>
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="downLoadImg('JPG')">导出JPG </a-menu-item>
                <a-menu-item key="2" @click="downLoadImg('PDF')">导出PDF </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>
        </div>
      </div>
      <div class="top-item" @mouseover="undoColor = color" @mouseout="undoColor = '#4a4a4a'">
        <div @click="backData">
          <a-tooltip>
            <template slot="title">
              撤回 CTRL+Z
            </template>
            <undo theme="outline" size="19" :fill="undoColor" :strokeWidth="4" />
          </a-tooltip>
        </div>
      </div>
      <div class="top-item" @mouseover="redoColor = color" @mouseout="redoColor = '#4a4a4a'">
        <div @click="nextData">
          <a-tooltip>
            <template slot="title">
              重做 CTRL+Y
            </template>
            <redo theme="outline" size="19" :fill="redoColor" :strokeWidth="4" />
          </a-tooltip>
        </div>
      </div>
      <div class=" top-item top-item-left">
        <div class="top-slider">
          <a-slider
            style="position:relative;top:-1px;"
            transitionName=""
            :default-value="100"
            :value="sliderValue"
            :max="200"
            :min="30"
            @change="sliderHandledChange"
          />
          <a-input-number
            style="width:60px !important;margin-left:3px"
            @change="sliderHandledChange"
            v-model="sliderValue"
            :min="0"
            :max="200"
          />
        </div>
      </div>
      <div class="top-item">
        <!-- <div class="top-item-hover" style='left:-7px;z-index:101;width:83px'></div> -->
        <div style="background-color:#fff !important">
          <a-tooltip>
            <template slot="title">
              字号
            </template>
            <a-select
              v-if="
                selectTextAreaData.every(item =>
                  item.characterSentences.every(
                    text => text.fontSize == selectTextAreaData[0].characterSentences[0].fontSize
                  )
                )
              "
              :filter-option="filterOption"
              show-search
              :disabled="!selectTextAreaData.length"
              @search="fontChange"
              placeholder="请选择字号"
              v-model="fontSize"
              @change="textFontSizeBatchStyle"
              :dropdownMenuStyle="{ maxHeight: '450px !important' }"
              style="width: 70px;font-size:14px;position: relative"
            >
              <a-select-option :value="item.value" v-for="item in fontSizes" :key="item.label">
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-select
              v-else
              :value="'-'"
              placeholder="请选择字号"
              @change="textFontSizeBatchStyle"
              :disabled="!selectTextAreaData.length"
              style="width: 70px;font-size:14px;"
            >
              <a-select-option :value="'-'" :key="'-'">-</a-select-option>
              <a-select-option :value="item.value" v-for="item in fontSizes" :key="item.label">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-tooltip>
        </div>
      </div>
      <div
        class="top-item"
        v-if="checkPermission('fatchStraw:local:view') && taskDetail.departName.indexOf('原创部') == -1"
        @mouseover="color3 = color"
        @mouseout="color3 = '#4a4a4a'"
      >
        <div @click="changeIsAddAnnotation">
          <a-tooltip transitionName="">
            <template slot="title">
              留言
            </template>
            <local-two theme="outline" size="21" :fill="isAddAnnotation ? color : color3" :strokeWidth="3" />
          </a-tooltip>
        </div>
      </div>
      <div
        class="top-item"
        v-if="checkPermission('fatchStraw:platte:view')"
        id="platte-option"
        @mouseover="color1 = color"
        @mouseout="color1 = '#4a4a4a'"
      >
        <div @click="openPlatteModal">
          <a-tooltip transitionName="">
            <template slot="title">
              画笔批注
            </template>
            <platte theme="outline" size="21" :fill="selectedPlatte ? color : color1" :strokeWidth="3" />
          </a-tooltip>
        </div>
      </div>
      <div class="top-item" @mouseover="color2 = color" @mouseout="color2 = '#4a4a4a'">
        <div>
          <a-popover transitionName="" placement="bottom" trigger="click" :overlayStyle="{ width: '235px' }">
            <div slot="content" class="sync-view">
              <a-checkbox
                style="margin-right:8px"
                :indeterminate="indeterminate"
                :checked="checkAll"
                @change="onCheckAllChange"
              >
                <span>全选</span>
              </a-checkbox>
              <a-checkbox-group v-model="checkSyncTabs" :options="getSyncOptions()" />
              <div class="sync-view-input-list">
                <template v-for="syncTab in checkSyncTabsWidth">
                  <div :key="syncTab.label">
                    <a-input-number
                      @blur="e => tabWidthChange(e, syncTab.label)"
                      @pressEnter="e => tabWidthChange(e, syncTab.label)"
                      v-model="syncTab.width"
                      :min="0"
                      :max="100"
                      style="width:80px;"
                    />
                    <span style="margin-left:5px;">%</span>
                  </div>
                </template>
              </div>
            </div>
            <a-tooltip transitionName="">
              <template slot="title">
                同步滚动
              </template>
              <reverse-operation-out theme="outline" size="21" :fill="scrollSync ? color : color2" :strokeWidth="3" />
            </a-tooltip>
          </a-popover>
        </div>
      </div>
      <div class="top-item" id="diff-option" @mouseover="color4 = color" @mouseout="color4 = '#4a4a4a'">
        <div @click="changeSelectedDiff">
          <a-tooltip transitionName="">
            <template slot="title">
              对比
            </template>
            <switch-contrast theme="outline" size="21" :fill="selectedDiff ? color : color4" :strokeWidth="3" />
          </a-tooltip>
        </div>
      </div>
      <!--       <div
        v-if="showOptions.some(showOption => showOption.list.some(option => option.title == '实时'))"
        class="top-item"
        @mouseover="eyeColor = color"
        @mouseout="eyeColor = '#4a4a4a'"
      >
        <div @click="showTextAreaChange">
          <a-tooltip>
            <template slot="title">
              {{ showTextArea == 'ON' ? '隐藏文本框' : '显示文本框' }}
            </template>
            <a-icon
              v-if="showTextArea == 'ON'"
              type="eye-invisible"
              :style="{ color: eyeColor, transition: 'all 0.3s', fontSize: '18px' }"
            />
            <a-icon v-else type="eye" :style="{ color: eyeColor, transition: 'all 0.3s', fontSize: '18px' }" />
          </a-tooltip>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Platte, DownPicture, ReverseOperationOut, LocalTwo, Redo, Undo, Save, SwitchContrast } from '@icon-park/vue'
import { checkPermission } from './../utils/hasPermission'
export default {
  components: {
    SwitchContrast,
    Platte,
    ReverseOperationOut,
    LocalTwo,
    Save,
    Redo,
    Undo,
    DownPicture
  },
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    showTextArea: {
      type: String,
      default: 'ON'
    },
    showOptions: {
      type: Array,
      default: () => []
    },
    selectedPlatte: {
      type: Boolean,
      default: false
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    authList: {
      type: Array,
      default: () => []
    },
    allOption: {
      type: Array,
      default: () => []
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    scrollSync: {
      type: Boolean,
      default: false
    },
    isAddAnnotation: {
      type: Boolean,
      default: false
    },
    isAutoSave: {
      type: Boolean,
      default: false
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    selectedDiff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      saveColor: 'rgba(0, 0, 0, 0.25)',
      undoColor: '#4a4a4a',
      redoColor: '#4a4a4a',
      downLoadColor: '#4a4a4a',
      checkAll: false,
      eyeColor: '#4a4a4a',
      color1: '#4a4a4a',
      sliderValue: 100,
      fontSize: 33,
      color2: '#4a4a4a',
      color3: '#4a4a4a',
      color4: '#4a4a4a',
      checkSyncTabs: [],
      indeterminate: false,
      visible: false,
      textBatchStyle: {
        baselineShift: 0,
        fontColor: '#000000',
        strokeColor: 'rgb(0, 0, 0)',
        strokeWidth: 0,
        fontFamily: null,
        fontSize: 33,
        letterSpacing: 0,
        lineSpacing: 1.15,
        outlineColor: '#fff',
        backgroundColor: '#fff',
        hasMark: 0,
        outlineWidth: 0,
        scaleX: 1,
        scaleY: 1,
        isBold: 0,
        isIncline: 0,
        textAlign: 'left',
        textHeight: 1,
        textWidth: 1,
        textLayout: 1,
        styleName: 'Regular',
        fontFamilyId: undefined
      },
      phoneticStyle: {
        fontColor: '#000000',
        fontSize: 17,
        lineHeight: 1.15,
        lineSpacing: 1.15,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left'
      },
      oldTextBatchStyle: {
        baselineShift: 0,
        fontColor: '#000000',
        strokeColor: '#000000',
        backgroundColor: '#fff',
        strokeWidth: 0,
        fontFamily: null,
        hasMark: 0,
        fontSize: 33,
        letterSpacing: 0,
        isBold: 0,
        isIncline: 0,
        lineSpacing: 1.15,
        textHeight: 1,
        textWidth: 1,
        outlineColor: '#ffffff',
        outlineWidth: 0,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left',
        textLayout: 1,
        styleName: 'Regular',
        fontFamilyId: undefined,
        indeterminate: true
      },
      fontSizes: [
        {
          label: 4,
          value: 17
        },
        {
          label: 5,
          value: 21
        },
        {
          label: 6,
          value: 25
        },
        {
          label: 7,
          value: 29
        },
        {
          label: 7.5,
          value: 31
        },
        {
          label: 8,
          value: 33
        },
        {
          label: 8.5,
          value: 35
        },
        {
          label: 9,
          value: 37
        },
        {
          label: 9.5,
          value: 39
        },
        {
          label: 10,
          value: 41
        },
        {
          label: 11,
          value: 45
        },
        {
          label: 12,
          value: 49
        },
        {
          label: 14,
          value: 57
        },
        {
          label: 18,
          value: 73
        },
        {
          label: 24,
          value: 97
        },
        {
          label: 30,
          value: 121
        },
        {
          label: 36,
          value: 145
        },
        {
          label: 48,
          value: 197
        },
        {
          label: 60,
          value: 241
        },
        {
          label: 72,
          value: 289
        }
      ],
      checkSyncTabsWidth: []
    }
  },
  watch: {
    checkSyncTabs() {
      if (this.checkSyncTabs.length) {
        this.changeScrollSync(true)
      } else {
        this.changeScrollSync(false)
      }
      let timeOut = setTimeout(() => {
        this.checkSyncTabsWidth = this.getSyncOptions().map(item => {
          return {
            label: item.label,
            width: null
          }
        })
        clearTimeout(timeOut)
      }, 300)
    },
    selectTextAreaData() {
      if (
        this.selectTextAreaData.length &&
        this.selectTextAreaData.every(item =>
          item.characterSentences.every(
            text => text.fontSize == this.selectTextAreaData[0].characterSentences[0].fontSize
          )
        )
      ) {
        this.fontSize = this.selectTextAreaData[0].characterSentences[0].fontSize
      }
    }
  },
  methods: {
    tabWidthChange(e, label) {
      this.$emit('changeSyncPer', { value: e.target.value, label })
    },
    changeSyncWidth() {
      let timeOut = setTimeout(() => {
        this.checkSyncTabsWidth = this.getSyncOptions().map(item => {
          return {
            label: item.label,
            width: null
          }
        })
        clearTimeout(timeOut)
      }, 300)
    },
    fontChange(e) {
      if (e > 0 == false) return
      if (e.indexOf('.') > -1 && e.split('.')[1].length > 1) return
      if (this.fontSizes.every(item => item.label != e)) {
        this.fontSizes.push({ value: e * 4 + 1, label: e })
        this.fontSizes = this.fontSizes.sort((a, b) => a.label - b.label)
      }
    },
    textFontSizeBatchStyle(value) {
      this.fontSize = value
      this.$emit('textBatchChange', this.fontSize)
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    showTextAreaChange() {
      if (this.showTextArea == 'ON') {
        this.$emit('showTextAreaChange', 'OFF')
      } else {
        this.$emit('showTextAreaChange', 'ON')
      }
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        indeterminate: false,
        checkAll: e.target.checked
      })
      if (e.target.checked) {
        this.checkSyncTabs = []
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(option => {
            if (option.checked) {
              this.checkSyncTabs.push(option.title)
            }
          })
        })
      } else {
        this.checkSyncTabs = []
      }
    },
    getSyncOptions() {
      let arr = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          if (option.checked) {
            arr.push({
              label: option.title,
              value: option.title
            })
          }
          /*           arr.push({
            label: option.title,
            value: option.title
          }) */
        })
      })
      return arr
    },
    changeCheckSyncTabs() {
      this.checkSyncTabs = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          if (option.checked) {
            this.checkSyncTabs.push(option.title)
          }
        })
      })
      this.indeterminate = false
      this.checkAll = true
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    downLoadImg(type) {
      /* if (!this.changeAll) return */
      this.$emit('downLoadImg', type)
    },
    changeSelectedDiff() {
      if (!this.changeAll) return
      this.$emit('changeSelectedDiff')
    },
    changeAutoSave() {
      if (!this.changeAll) return
      this.$emit('changeAutoSave')
    },
    backData() {
      if (!this.changeAll) return
      this.$emit('backData')
    },
    nextData() {
      if (!this.changeAll) return
      this.$emit('nextData')
    },
    changeIsAddAnnotation() {
      if (!this.changeAll) return
      this.$emit('changeIsAddAnnotation')
    },
    changeScrollSync(type) {
      this.$emit('changeScrollSync', type)
      this.indeterminate = !!this.checkSyncTabs.length && this.checkSyncTabs.length < this.getSyncOptions().length
      this.checkAll = this.checkSyncTabs.length === this.getSyncOptions().length
    },
    openPlatteModal() {
      if (!this.changeAll) return
      /* if (this.taskDetail.departName.indexOf('原创部') > -1) this.$message.warning('注意：请不要跨虚线绘制任何内容，可能会导致批注内容导出不完整') */
      /* if (this.taskDetail.isLocal) this.$message.warning('注意：请不要跨虚线绘制任何内容，可能会导致批注内容导出不完整') */
      this.$emit('openPlatteModal')
    },
    handleMouseWheel(event) {
      const delta = event.deltaY || event.wheelDelta
      const direction = delta > 0 ? 1 : -1
      this.$refs.topButtonList.scrollLeft += direction * 50
    },
    hide() {
      this.visible = false
    },
    sliderHandledChange(value) {
      if (value > 200) {
        value = 200
        this.sliderValue = 200
      }
      if (value < 0) {
        this.sliderValue = 0
        value = 0
      }
      this.sliderValue = value
      let v = value + '%'
      this.$emit('changeShowImgListWidth', v)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .sync-view {
  line-height: 32px !important;
  .ant-checkbox-group {
    width: 100% !important;
    .ant-checkbox-wrapper {
      width: 100% !important;
    }
  }
}
.top-slider .ant-slider {
  width: 150px !important;
}
.top-button-list {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.3s;
  padding-left: 24px;
  height: 100%;
  position: relative;
  .shortcut-prompt {
    position: absolute;
    right: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    > span:hover {
      color: var(--theme-color);
    }
  }
  > div {
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 100%;
    .top-item {
      height: 100%;
      display: flex;
      align-items: center;
      > div {
        padding: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background-color: rgba(109, 109, 109, 0.1);
        }
      }
      > .top-slider {
        cursor: default;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
