var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "topButtonList",
      staticClass: "top-button-list",
      on: { mousewheel: _vm.handleMouseWheel },
    },
    [
      _c(
        "span",
        { staticClass: "shortcut-prompt" },
        [
          _c(
            "a-popover",
            { attrs: { placement: "bottomLeft" } },
            [
              _c("template", { slot: "content" }, [
                _c("div", [
                  _c("div", [_vm._v("留言：N")]),
                  _c("div", [_vm._v("批注：W")]),
                  _c("div", [_vm._v("撤回：Ctrl+Z")]),
                  _c("div", [_vm._v("重做：Ctrl+Y")]),
                  _c("div", [_vm._v("文本框（显/隐）：Ctrl+H")]),
                  _c("div", [_vm._v("滚动画布：方向键↑↓")]),
                ]),
              ]),
              _c("span", [_vm._v("\n        快捷键提示\n      ")]),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "top-item",
            style: _vm.isAutoSave ? "" : "cursor: no-drop;",
          },
          [
            _c(
              "div",
              {
                style: _vm.isAutoSave
                  ? ""
                  : "cursor: no-drop;background-color: rgba(109, 109, 109, 0);",
                on: { click: _vm.changeAutoSave },
              },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            保存 CTRL+S\n          "),
                    ]),
                    _c("save", {
                      attrs: {
                        theme: "outline",
                        size: "20",
                        fill: _vm.isAutoSave ? _vm.color : _vm.saveColor,
                        strokeWidth: 4,
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _vm.checkPermission("fatchStraw:upload:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                style: _vm.isAutoSave ? "" : "cursor: no-drop;",
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      mouseover: function ($event) {
                        _vm.downLoadColor = _vm.color
                      },
                      mouseout: function ($event) {
                        _vm.downLoadColor = "#4a4a4a"
                      },
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      {
                        attrs: { transitionName: "" },
                        on: {
                          mouseover: function ($event) {
                            $event.stopPropagation()
                          },
                          mouseout: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v("导出")]),
                        ]),
                        _c(
                          "a-dropdown",
                          { attrs: { trigger: ["click"], transitionName: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  top: "2px",
                                  width: "40px",
                                },
                              },
                              [
                                _c("down-picture", {
                                  attrs: {
                                    theme: "outline",
                                    size: "20",
                                    fill: _vm.downLoadColor,
                                    strokeWidth: 3,
                                  },
                                }),
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "12px",
                                    position: "relative",
                                    top: "-3px",
                                    right: "-4px",
                                  },
                                  attrs: { type: "down" },
                                }),
                                _c("div", {
                                  staticClass: "top-item-hover",
                                  staticStyle: { top: "-4px", width: "46px" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downLoadImg("JPG")
                                      },
                                    },
                                  },
                                  [_vm._v("导出JPG ")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downLoadImg("PDF")
                                      },
                                    },
                                  },
                                  [_vm._v("导出PDF ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "top-item",
            on: {
              mouseover: function ($event) {
                _vm.undoColor = _vm.color
              },
              mouseout: function ($event) {
                _vm.undoColor = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "div",
              { on: { click: _vm.backData } },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            撤回 CTRL+Z\n          "),
                    ]),
                    _c("undo", {
                      attrs: {
                        theme: "outline",
                        size: "19",
                        fill: _vm.undoColor,
                        strokeWidth: 4,
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "top-item",
            on: {
              mouseover: function ($event) {
                _vm.redoColor = _vm.color
              },
              mouseout: function ($event) {
                _vm.redoColor = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "div",
              { on: { click: _vm.nextData } },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            重做 CTRL+Y\n          "),
                    ]),
                    _c("redo", {
                      attrs: {
                        theme: "outline",
                        size: "19",
                        fill: _vm.redoColor,
                        strokeWidth: 4,
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "top-item top-item-left" }, [
          _c(
            "div",
            { staticClass: "top-slider" },
            [
              _c("a-slider", {
                staticStyle: { position: "relative", top: "-1px" },
                attrs: {
                  transitionName: "",
                  "default-value": 100,
                  value: _vm.sliderValue,
                  max: 200,
                  min: 30,
                },
                on: { change: _vm.sliderHandledChange },
              }),
              _c("a-input-number", {
                staticStyle: { width: "60px !important", "margin-left": "3px" },
                attrs: { min: 0, max: 200 },
                on: { change: _vm.sliderHandledChange },
                model: {
                  value: _vm.sliderValue,
                  callback: function ($$v) {
                    _vm.sliderValue = $$v
                  },
                  expression: "sliderValue",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "top-item" }, [
          _c(
            "div",
            { staticStyle: { "background-color": "#fff !important" } },
            [
              _c(
                "a-tooltip",
                [
                  _c("template", { slot: "title" }, [
                    _vm._v("\n            字号\n          "),
                  ]),
                  _vm.selectTextAreaData.every((item) =>
                    item.characterSentences.every(
                      (text) =>
                        text.fontSize ==
                        _vm.selectTextAreaData[0].characterSentences[0].fontSize
                    )
                  )
                    ? _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "70px",
                            "font-size": "14px",
                            position: "relative",
                          },
                          attrs: {
                            "filter-option": _vm.filterOption,
                            "show-search": "",
                            disabled: !_vm.selectTextAreaData.length,
                            placeholder: "请选择字号",
                            dropdownMenuStyle: {
                              maxHeight: "450px !important",
                            },
                          },
                          on: {
                            search: _vm.fontChange,
                            change: _vm.textFontSizeBatchStyle,
                          },
                          model: {
                            value: _vm.fontSize,
                            callback: function ($$v) {
                              _vm.fontSize = $$v
                            },
                            expression: "fontSize",
                          },
                        },
                        _vm._l(_vm.fontSizes, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.label, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.label) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _c(
                        "a-select",
                        {
                          staticStyle: { width: "70px", "font-size": "14px" },
                          attrs: {
                            value: "-",
                            placeholder: "请选择字号",
                            disabled: !_vm.selectTextAreaData.length,
                          },
                          on: { change: _vm.textFontSizeBatchStyle },
                        },
                        [
                          _c(
                            "a-select-option",
                            { key: "-", attrs: { value: "-" } },
                            [_vm._v("-")]
                          ),
                          _vm._l(_vm.fontSizes, function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.label, attrs: { value: item.value } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.label) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm.checkPermission("fatchStraw:local:view") &&
        _vm.taskDetail.departName.indexOf("原创部") == -1
          ? _c(
              "div",
              {
                staticClass: "top-item",
                on: {
                  mouseover: function ($event) {
                    _vm.color3 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color3 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "div",
                  { on: { click: _vm.changeIsAddAnnotation } },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { transitionName: "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("\n            留言\n          "),
                        ]),
                        _c("local-two", {
                          attrs: {
                            theme: "outline",
                            size: "21",
                            fill: _vm.isAddAnnotation ? _vm.color : _vm.color3,
                            strokeWidth: 3,
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.checkPermission("fatchStraw:platte:view")
          ? _c(
              "div",
              {
                staticClass: "top-item",
                attrs: { id: "platte-option" },
                on: {
                  mouseover: function ($event) {
                    _vm.color1 = _vm.color
                  },
                  mouseout: function ($event) {
                    _vm.color1 = "#4a4a4a"
                  },
                },
              },
              [
                _c(
                  "div",
                  { on: { click: _vm.openPlatteModal } },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { transitionName: "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("\n            画笔批注\n          "),
                        ]),
                        _c("platte", {
                          attrs: {
                            theme: "outline",
                            size: "21",
                            fill: _vm.selectedPlatte ? _vm.color : _vm.color1,
                            strokeWidth: 3,
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "top-item",
            on: {
              mouseover: function ($event) {
                _vm.color2 = _vm.color
              },
              mouseout: function ($event) {
                _vm.color2 = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "a-popover",
                  {
                    attrs: {
                      transitionName: "",
                      placement: "bottom",
                      trigger: "click",
                      overlayStyle: { width: "235px" },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "sync-view",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _c(
                          "a-checkbox",
                          {
                            staticStyle: { "margin-right": "8px" },
                            attrs: {
                              indeterminate: _vm.indeterminate,
                              checked: _vm.checkAll,
                            },
                            on: { change: _vm.onCheckAllChange },
                          },
                          [_c("span", [_vm._v("全选")])]
                        ),
                        _c("a-checkbox-group", {
                          attrs: { options: _vm.getSyncOptions() },
                          model: {
                            value: _vm.checkSyncTabs,
                            callback: function ($$v) {
                              _vm.checkSyncTabs = $$v
                            },
                            expression: "checkSyncTabs",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "sync-view-input-list" },
                          [
                            _vm._l(_vm.checkSyncTabsWidth, function (syncTab) {
                              return [
                                _c(
                                  "div",
                                  { key: syncTab.label },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: { width: "80px" },
                                      attrs: { min: 0, max: 100 },
                                      on: {
                                        blur: (e) =>
                                          _vm.tabWidthChange(e, syncTab.label),
                                        pressEnter: (e) =>
                                          _vm.tabWidthChange(e, syncTab.label),
                                      },
                                      model: {
                                        value: syncTab.width,
                                        callback: function ($$v) {
                                          _vm.$set(syncTab, "width", $$v)
                                        },
                                        expression: "syncTab.width",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v("%")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { transitionName: "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("\n              同步滚动\n            "),
                        ]),
                        _c("reverse-operation-out", {
                          attrs: {
                            theme: "outline",
                            size: "21",
                            fill: _vm.scrollSync ? _vm.color : _vm.color2,
                            strokeWidth: 3,
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "top-item",
            attrs: { id: "diff-option" },
            on: {
              mouseover: function ($event) {
                _vm.color4 = _vm.color
              },
              mouseout: function ($event) {
                _vm.color4 = "#4a4a4a"
              },
            },
          },
          [
            _c(
              "div",
              { on: { click: _vm.changeSelectedDiff } },
              [
                _c(
                  "a-tooltip",
                  { attrs: { transitionName: "" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            对比\n          "),
                    ]),
                    _c("switch-contrast", {
                      attrs: {
                        theme: "outline",
                        size: "21",
                        fill: _vm.selectedDiff ? _vm.color : _vm.color4,
                        strokeWidth: 3,
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }